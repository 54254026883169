@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

@layer base {
  html {
    font-family: var(--font-sans);
    scroll-behavior: smooth;
  }
  
  body {
    @apply bg-secondary text-primary;
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-bold tracking-tight;
    font-family: 'Inter', sans-serif;
  }
  
  h1 {
    @apply text-4xl md:text-5xl lg:text-6xl;
  }
  
  h2 {
    @apply text-3xl md:text-4xl;
  }
  
  h3 {
    @apply text-2xl md:text-3xl;
  }
  
  a {
    @apply transition-colors duration-300;
  }
}

@layer components {
  .container-custom {
    @apply container mx-auto px-4 md:px-8 max-w-7xl;
  }
  
  .btn {
    @apply inline-flex items-center justify-center rounded-none px-6 py-3 text-base font-medium transition-all duration-300;
  }
  
  .btn-primary {
    @apply bg-primary text-white hover:bg-opacity-90;
  }
  
  .btn-secondary {
    @apply bg-transparent border border-primary text-primary hover:bg-primary hover:text-white;
  }
  
  .nav-link {
    @apply relative inline-block px-2 py-1 font-medium transition-colors duration-300;
  }
  
  .nav-link::after {
    @apply content-[''] absolute left-0 bottom-0 w-0 h-0.5 bg-accent transition-all duration-300;
  }
  
  .nav-link:hover::after {
    @apply w-full;
  }
  
  .nav-link.active::after {
    @apply w-full;
  }
  
  .client-logo {
    @apply grayscale opacity-70 transition-all duration-300 hover:grayscale-0 hover:opacity-100;
  }
  
  .service-card {
    @apply bg-white p-6 shadow-md transition-all duration-500 hover:shadow-xl hover:-translate-y-1;
  }
}

/* Swiss Design Elements */
.swiss-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
}

.swiss-typography {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  letter-spacing: -0.02em;
  line-height: 1.2;
} 